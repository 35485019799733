
















































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import IChosenAskuePointProps from "@/models/consumption-table/ChosenAskuePointProps.interface";
import AppApiMixin from "../mixins/AppApi.vue";
import DropdownWithDatePickerWithoutRange from "../hoc/DropdownWithDatePickerWithoutRange.vue";
import XButton from "../SimpleButton.vue";
import XDialog from "../SimpleDialog.vue";

@Component({
  components: {
    XDialog,
    DropdownWithDatePickerWithoutRange,
    XButton,
  },
  computed: {
    ...mapGetters("user", { openMonth: "month" }),
    ...mapGetters("contract", { contractId: "id" }),
  },
})
class DocExchangeDialog extends Mixins(AppApiMixin, XDialog) {
  [x: string]: any;

  @Prop({ required: true }) readonly showDialog!: boolean;
  @Prop({ required: true }) readonly pointProps!: IChosenAskuePointProps;

  submitErrorText = "";

  @Watch("showDialog")
  public showDialogChanged() {
    this.chosenFileName = "";
    this.submitErrorText = "";
  }

  $refs!: {
    fileInput: HTMLInputElement;
  };

  data() {
    return {
      chosenFileName: "",
      useAgreeButtonLoading: false,
    };
  }

  public get isValid(): boolean {
    return !!this.chosenFileName;
  }

  public onAgree(file: File, evtName: string): void {
    this.useAgreeButtonLoading = true;

    const { contractId, pointProps, uploadConsumptionDocument } = this;
    // Извлечь необходимые данные для запроса в otherPointProps.
    const { name, ...otherPointProps } = pointProps;
    const formData = new FormData();

    // Подготовить "тело запроса".
    const reqBody = { contractId, file, ...otherPointProps };
    Object.entries(reqBody).forEach(([key, value]) => formData.set(key, value));

    this.submitErrorText = "";
    uploadConsumptionDocument(formData)
      .then(() => this.$emit(evtName))
      .catch((e) => {
          if (e == 'Не найдены данные для подкачки' ||
              e == 'Период уже закрыт') {
            this.submitErrorText = e;
          } else {
            console.error()
          }
        }
      )
      .finally(() => (this.useAgreeButtonLoading = false));
  }
}

export default DocExchangeDialog;
